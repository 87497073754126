import React from "react";
import Img from "gatsby-image"
import {
  Row,
  Col,
} from "react-bootstrap";

export default function ({ title, fluid1, child1, fluid2, child2 }) {
  return (
    <div className={'img-card-vert'}>
      <Row><Col className="titleCol"><h3>{title}</h3></Col></Row>
      <Row>
        <Col sm>
            <div className="img-img">
              <Img fluid={fluid1}></Img>
            </div>
            <div className="img-txt" >
            {child1}
          </div>
        </Col>
        <Col sm> 
            <div className="img-img">
              <Img fluid={fluid2}></Img>
            </div>
            <div className="img-txt" >
            {child2}
          </div>
          
        </Col>
      </Row>

      <Row><Col><br /><hr /><br /></Col></Row>
    </div >

  );
}
