import React from "react";
import Img from "gatsby-image"
import {
  Row,
  Col,
} from "react-bootstrap";

export default function ({ title, fluid, reverse, children }) {
  return (
    <React.Fragment>
      <Row>
        <Col className="titleCol">
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col className={reverse?'img-card reverse':'img-card'}>
          {fluid &&
            <div className="img-img">
              <Img fluid={fluid} className='cg-picture'></Img>
            </div>
          }
          <div className="img-txt" >
            {children}
          </div>
        </Col>
      </Row>

      <Row><Col><br /><hr /><br /></Col></Row>
    </React.Fragment>

  );
}
