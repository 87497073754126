import React from "react";
//import ContactForm from "../components/contactForm";
import SEO from "../components/seo";
import Header from "../components/header";
import ImgCard from "../components/imgCard";
import ImgCardVert from "../components/imgCardVert";
import Layout from "../components/layout";
import SectionHeading from "../components/sectionHeading";
import Hr from "../components/hr"
import ContactForm from "../components/contactForm"
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";



const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          tagline
          ctaText
          ctaLink
        }
      },
      onsite: file(relativePath: { eq: "fealy_onsite.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      footings: file(relativePath: { eq: "fealy_footings.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      floor: file(relativePath: { eq: "fealy_floor_1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      walls: file(relativePath: { eq: "atlas_bitle-8x15-interior_sm.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      handles: file(relativePath: { eq: "fealy_handle.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      vents: file(relativePath: { eq: "usp_4_vent_1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      shingles: file(relativePath: { eq: "shingle_types.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      smartside: file(relativePath: { eq: "fealy_smartside.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      overhang: file(relativePath: { eq: "overhang_usp2.1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      trim: file(relativePath: { eq: "trim1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      roof_leanto: file(relativePath: { eq: "fealy_4x8_leanto.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      roof_gable: file(relativePath: { eq: "fealy_shed_blue.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>

      {/* TODO new above fold component? */}
      <SEO
        title='Features of Our Sheds'
        description='Schedule your shed install date'
        canonical='features'
        ctaText={data.site.siteMetadata.ctaText}
        ctaLink={data.site.siteMetadata.ctaLink}
      />
      <Header 
        active='features' 
        ctaText={data.site.siteMetadata.ctaText}
        ctaLink={data.site.siteMetadata.ctaLink}
        />
      {/* END new above fold */}

      <main className='cg-sectionLight'>
        <Container fluid>
          <Row>
            <Col>
              <SectionHeading>Features of Our Sheds</SectionHeading>
            </Col>
          </Row>

          <ImgCard title="Free Onsite Installation" fluid={data.onsite.childImageSharp.fluid}>
            You won’t have to worry about taking down fences or having trucks drive over your lawn. We hand-carry all shed materials in through your gate.
            </ImgCard>
          <ImgCard title="Solid Block Footings" fluid={data.footings.childImageSharp.fluid} reverse>
            We level your shed on solid cement blocks making sure the base does not directly come in contact with the ground. This ensures years of service without rot or insect damage.
            </ImgCard>
          <ImgCard title="Heavy Duty Floor" fluid={data.floor.childImageSharp.fluid} >
          We use 2x6 floor joist (16”OC) in all our sheds. Most other shed companies use 2x4's. Also we use 3/4” exterior plywood. (Not OSB) Our floor is super strong and will stand the test of time.
            </ImgCard>
          <ImgCard title="2x4 Wall Construction" fluid={data.walls.childImageSharp.fluid} reverse>
          We frame all our walls with full 2x4 wall studs with 6.5 foot sidewalls. (Optionally, you can have studs at 16”OC and sidewall up to 8ft.)
            </ImgCard>
          <ImgCard title="Locking Handle" fluid={data.handles.childImageSharp.fluid}>
          Our doors have a locking tee handle with keys to secure your property.
            </ImgCard>
          <ImgCard title="Gable Vents" fluid={data.vents.childImageSharp.fluid} reverse>
          We include gable air vents on all our sheds. Some companies charge extra.
            </ImgCard>
          <ImgCard title="30 Year Architectural Shingles" fluid={data.shingles.childImageSharp.fluid}>
          We offer a choice of five popular shingle colors to choose from. 
          Learn about <em>architectural</em> vs <em>3-tab</em> shingles in our free <a className="cg-linkDark" href='https://info.uticashedpros.com/f/checklist' >Shed Buying Checklist</a>
            </ImgCard>
          <ImgCard title="LP Smart Side Wall Panels" fluid={data.smartside.childImageSharp.fluid} reverse>
          We use LP Smartside for our shed walls. It is an exterior grade panel pre-primed a natural tan color. It is treated so it will not absorb water.
          </ImgCard>
          <ImgCard title='4" Roof Overhangs' fluid={data.overhang.childImageSharp.fluid}>
            Our sheds have a 4" overhang around the roofline to help keep the shed dry in bad weather.
          </ImgCard>
          <ImgCard title="White No Maintenance PVC Trim" fluid={data.trim.childImageSharp.fluid} reverse>
            If you look at old sheds you quickly realize that the first thing to deteriorate is the trim boards used. We eliminate that from happening on our sheds by only using PVC trim boards. They never need paint and they will never rot.
          </ImgCard>
          <ImgCardVert 
            title="Two Roof Styles Available"
            fluid1={data.roof_leanto.childImageSharp.fluid}
            fluid2={data.roof_gable.childImageSharp.fluid}
            child1='A "Lean-To" style has a flat slanted roof. They often go up against houses or fences.'
            child2=' A "Gable" style  has the typical "A-frame" shape and are most popular.'
          />

          <Row>
            <Col>
              <a className="cg-secondary btn btn-secondary" href='/pricing' >Get Price List</a>
              <Link className="cg-dark btn btn-primary" to={data.site.siteMetadata.ctaLink} >{data.site.siteMetadata.ctaText}</Link>
            </Col>
          </Row>
        </Container>
      </main>
      <Hr />
      <ContactForm />
    </Layout>
  )
};

export default Features;
